import { Form, Input, Button, message } from "antd";
// import "antd/dist/antd.min."
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/apiCalls";
import "./adminLogin.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import React from "react";

const AdminLogin = () => {
  const loggedIn = useSelector((state) => state.currentUser);
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    const username = values.username;
    const password = values.password;
    login(dispatch, { username, password });
  };

  useEffect(() => {
    if (loggedIn !== null) {
      message.success("Login successful. Redirecting you to the admin panel..");
      setTimeout(() => {
        return navigate("/admin");
      }, 3000);
    }
  }, [loggedIn]);

  return (
    <div className="AdminLogin">
      <div className="container">
        <div className="title"> LOGIN | KEYS AND CONTRACTS </div>
        <br />
        <div className="form">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label={<label style={{ color: "white" }}>Username</label>}
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<label style={{ color: "white" }}>Password</label>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "slategray", width: "200px" }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { AdminLogin };
