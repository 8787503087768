import React from "react";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import Icon, { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import "./footer.scss";
import { SubscribeForm } from "../SubscribeForm.tsx";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="section-seven">
        <div className="message">
          Schedule a call with our team at <span>Keys & Contracts</span> today!
        </div>
        <div className="content">
          <div className="left">
            <div>Keys & Contracts</div>
            <Link
              to="javascript:void(0)"
              onClick={(e) => {
                window.location.href = "mailto:support@keysandcontracts.com";
              }}
              className="link"
            >
              support@keysandcontracts.com
            </Link>
            <span></span>
          </div>
          <Divider type="vertical" className="divider" />
          <div className="middle">
            <span>Follow Us</span>
            <div className="icons">
              <Icon
                component={FacebookOutlined}
                className="icon"
                onClick={() =>
                  window.open("https://www.facebook.com/keysandcontracts1")
                }
              />
              <Icon
                component={InstagramOutlined}
                className="icon"
                onClick={() =>
                  window.open("https://www.instagram.com/keysandcontracts/")
                }
              />
            </div>
          </div>
          <Divider type="vertical" className="divider" />
          <div className="right">
            <span>Newsletter Subscription</span>
            <br />
            <br />
            <SubscribeForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
