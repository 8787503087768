import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  message,
  Switch,
  Upload,
  Rate,
  Modal,
  Divider,
} from "antd";
import { adminRequest } from "../../requestMethods";
import React, { useState } from "react";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import app from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { css } from "@emotion/css";
import { CSS } from "@dnd-kit/utilities";

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 10,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "Please enter a valid email address.",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: any;
}

const AddProperty = () => {
  const DraggableUploadListItem = ({
    originNode,
    file,
  }: DraggableUploadListItemProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: file.uid,
    });

    const style: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "move",
    };

    // prevent preview event when drag end
    const className = isDragging
      ? css`
          a {
            pointer-events: none;
          }
        `
      : "";

    return (
      <div
        ref={setNodeRef}
        style={style}
        className={className}
        {...attributes}
        {...listeners}
      >
        {/* hide error tooltip when dragging */}
        {file.status === "error" && isDragging
          ? originNode.props.children
          : originNode}
      </div>
    );
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const { Dragger } = Upload;

  const [active, setActive] = useState(false);
  const [images, setImages] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const beforeUpload = (file) => {
    if (
      !["image/jpeg", "image/png", "image/webp", "image/svg"].includes(
        file.type
      )
    ) {
      message.error(`${file.name} is not a valid image type`, 2);
      return null;
    }
    return false;
  };

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));

  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const photosUrl = [];

  const handleFinish = async (values) => {
    try {
      setSubmitting(true);

      await Promise.all(
        fileList.map(async (file) => {
          const fileName = new Date().getTime() + "_" + file.name;
          const storage = getStorage(app);
          const storageRef = ref(storage, fileName);
          try {
            const snapshot = await uploadBytesResumable(
              storageRef,
              file.originFileObj
            );
            const downloadURL = await getDownloadURL(snapshot.ref);

            photosUrl.push(downloadURL);
          } catch (e) {
            console.log(e);
          }
          setFileList(images);
        })
      );

      if (photosUrl.length > 0) {
        createProperty(values, photosUrl);
      }

      setFileList([]);
      message.success(`Images added successfully.`, 2);
    } catch (err) {
      console.log(err);
      message.error(`Error adding images.`, 2);
    } finally {
      setSubmitting(false);
    }
  };

  const setPropertyActive = () => {
    setActive(!active);
  };

  const createProperty = async (values, photos) => {
    try {
      const res = await adminRequest.post("/addProperty", {
        address: {
          street: values.street,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
        price: values.price,
        details: {
          type: values.type,
          beds: values.beds,
          baths: values.baths,
          sqft: values.sqft,
          yearBuilt: values.yearBuilt,
        },
        location_rating: values.locationRating,
        estimations: {
          arv: values.arv,
          rehab: values.rehab,
          rent: values.rent,
        },
        documents: [
          {
            title: values.title,
            link: values.link,
          },
        ],
        isActive: values.isActive,
        status: values.status,
        description: values.description,
        images: photos,
      });

      if (res.data === "Property creation failed!") {
        message.error(
          "Failed to add new property. Property street address already exist in the system."
        );
      } else {
        message.success(
          "Property created successfully. Please refresh the page."
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = () => {
    message.error("Registration failed! Please fill up required values.");
  };

  const [percent, setPercent] = useState(0);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  return (
    <div className="Registration">
      <div className="container">
        <h1>Add Property</h1>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            General Information
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />{" "}
          <Form.Item
            name="street"
            label="Street"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: 123 Main Street" />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: New York City" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: NY" />
          </Form.Item>
          <Form.Item
            name="zip"
            label="Zip Code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: 12345" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[]}>
            <Input.TextArea showCount maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="active"
            label="Show on site"
            valuePropName="checked"
            onClick={setPropertyActive}
          >
            <Switch />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[]}>
            <Select placeholder="Please Select...">
              <Select.Option value="Active" key="active">
                Active
              </Select.Option>
              <Select.Option value="Pending" key="pending">
                Pending
              </Select.Option>
              <Select.Option value="Contract" key="contract">
                Under Contract
              </Select.Option>
              <Select.Option value="Sold" key="sold">
                Sold
              </Select.Option>
            </Select>
          </Form.Item>
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            Property Details
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />
          <Form.Item
            name="type"
            label="Property Type"
            rules={[
              {
                required: "true",
              },
            ]}
          >
            <Select placeholder="Please Select...">
              <Select.Option value="Single-Family" key="Single-Family">
                Single-Family
              </Select.Option>
              <Select.Option value="Multi-Family" key="Multi-Family">
                Multi-Family
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="beds"
            label="Beds"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
                max: 10,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="baths"
            label="Baths"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
                max: 10,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="sqft"
            label="Sqft"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="yearBuilt"
            label="Year Built"
            rules={[
              {
                required: "true",
                type: "number",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="locationRating"
            label="Location Rating"
            rules={[
              {
                required: "true",
              },
            ]}
          >
            <Rate defaultValue={0} allowHalf />
          </Form.Item>
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            Estimations
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />{" "}
          <Form.Item
            name="arv"
            label="ARV (After-Repair Value)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            name="rehab"
            label="Rehab"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            name="rent"
            label="Rent"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />{" "}
          <Form.Item
            name="photo"
            label="Upload Photo"
            valuePropName="photo"
            getValueFromEvent={normFile}
          >
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext
                items={fileList.map((i) => i.uid)}
                strategy={verticalListSortingStrategy}
              >
                <Dragger
                  listType="picture-card"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  onRemove={onRemove}
                  multiple
                  itemRender={(originNode, file) => (
                    <DraggableUploadListItem
                      originNode={originNode}
                      file={file}
                    />
                  )}
                >
                  <Modal
                    open={previewVisible}
                    title={"Image Preview"}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="preview"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </SortableContext>
            </DndContext>
            {/* <Dragger
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUpload}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={onRemove}
              multiple
            >
              <Modal
                open={previewVisible}
                title={"Image Preview"}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="preview"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
              <div className="uploadIcon">
                <UploadOutlined />
              </div>
              <div className="uploadText">
                <p>Drag and drop here</p>
                <p>OR</p>
                <p>Click</p>
              </div>
            </Dragger> */}
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit" style={{ width: "50vw" }}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export { AddProperty };
