import React from "react";
import "./landingPage.scss";
import BackgroundImage from "../../images/landingPage_headerBg.png";
import SectionTwo_Img1 from "../../images/image_1.png";
import Multi_Family from "../../images/multi-family.png";
import Single_Family from "../../images/single-family.png";
import Single_Family_2 from "../../images/single_family_2.png";
import Location_Icon from "../../images/location-icon.png";
import Chart_Icon from "../../images/chart-icon.png";
import Communication_Icon from "../../images/communication-icon.png";
import Investors_Icon from "../../images/investors-icon.png";
import Money_Icon from "../../images/money-icon.png";
import Wallet_Icon from "../../images/wallet-icon.png";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Divider, Card, Col, Row } from "antd";
import { Footer } from "../../components/Footer/Footer.tsx";
import { Navigation } from "../../components/Navigation/Navigation.tsx";

const LandingPage = () => {
  const DISCOVER_PROPERTIES = [
    { img: Multi_Family, delay: 300 },
    { img: Single_Family, delay: 400 },
    { img: Single_Family_2, delay: 500 },
  ];
  const JOURNEY_CARDS = [
    { stats: "100+", label: "Projects Completed" },
    { stats: "200+", label: "Sold Properties" },
    { stats: "50+", label: "In-Progress" },
  ];
  const PORTFOLIO_ICONS_LEFT = [
    { icon: Location_Icon, label: "Location", left: true },
    { icon: Communication_Icon, label: "Team", left: true },
    { icon: Wallet_Icon, label: "Budget", left: true },
    { icon: Chart_Icon, label: "Growth" },
    { icon: Investors_Icon, label: "Investors" },
    { icon: Money_Icon, label: "Finance" },
  ];
  return (
    <div className="LandingPage">
      <div className="section-one">
        <img src={BackgroundImage} alt="bg" className="background"></img>
        <Navigation />
        <div className="content">
          <div className="title">
            Discover a New Level of Real Estate Investing with
            <span className={`o-t`}> Keys & Contracts</span>
          </div>
          <Divider className="divider" />
          <Button
            className={`properties`}
            size="large"
            type="primary"
            onClick={() => (window.location.href = "/properties")}
          >
            Browse Properties
          </Button>
        </div>
      </div>
      <div className="section-two">
        <div className="content">
          <ScrollAnimation animateIn="fadeIn">
            <img src={SectionTwo_Img1} alt="img1" className="image" />
          </ScrollAnimation>
          <div className="right">
            <div className="text">
              <ScrollAnimation animateIn="fadeIn" delay={10}>
                <span className="heading">Discover</span> your perfect property
                or sell with ease: Your ultimate destination for real estate buy
                or sell - <span className="heading">Keys & Contracts</span>
                <Divider className="divider" />
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" delay={300}>
                <div className="message">
                  Whether you're looking to buy your dream home, sell your
                  existing property, or invest in real estate for long-term
                  wealth and financial security, we are here to help.
                </div>

                <div className="button">
                  <Button
                    className="about-us"
                    size="large"
                    onClick={() => (window.location.href = "/aboutUs")}
                  >
                    About Us
                  </Button>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="section-three">
        <div className="items">
          <div className="content">
            <div className="title">
              <ScrollAnimation animateIn="fadeIn" delay={10}>
                <div className="left">
                  <div className="text">
                    <div className="heading">Discover Properties</div>
                    <div className="message">
                      Are you looking for a
                      <span className="highlights"> smart </span> and{" "}
                      <span className="highlights"> profitable </span>
                      investment opportunity? Our properties are carefully
                      selected to meet the needs of investors in all levels. Our
                      team of experts will help you find the{" "}
                      <span className="highlights"> perfect property </span>
                      that fits your investment strategy, budget, and goals.
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="images">
              {DISCOVER_PROPERTIES.map((item) => {
                return (
                  <ScrollAnimation
                    key={item.img}
                    animateIn="flipInY"
                    delay={item.delay}
                  >
                    <img
                      src={item.img}
                      alt="img1"
                      className="image"
                    />
                  </ScrollAnimation>
                );
              })}
            </div>
            <ScrollAnimation animateIn="fadeIn" delay={100}>
              <div className="button">
                <Button
                  className="about-us"
                  size="large"
                  onClick={() => (window.location.href = "/properties")}
                >
                  Browse Properties
                </Button>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="section-four">
        <ScrollAnimation animateIn="fadeIn" delay={200}>
          <div className="title">Our Journey</div>
        </ScrollAnimation>
        <div className="cards">
          <Row gutter={18}>
            {JOURNEY_CARDS.map((item) => {
              return (
                <Col span={8} key={item.label}>
                  <ScrollAnimation animateIn="fadeIn" delay={300}>
                    <Card className="card" bordered={false}>
                      <span className="card-content">{item.stats}</span>
                      <span className="card-label">{item.label}</span>
                    </Card>
                  </ScrollAnimation>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="section-five">
        <div className="content">
          <ScrollAnimation animateIn="fadeIn" delay={200}>
            <div className="left">
              <div className="title">Sell your house!</div>
              <div className="message">
                We offer <span className="highlights">competitive pricing</span>{" "}
                and a<span className="highlights"> hassle-free experience</span>{" "}
                that will make selling your home a breeze. Our streamlined
                process ensures that you get the
                <span className="highlights"> best offer possible</span>, and we
                take care of all the paperwork and legalities so that you can
                focus on your next adventure. <br />
                <br />
                Let us help you turn your house into cash today!
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={300}>
            <div className="right">
              <div className="title">
                Criteria
                <div className="message">
                  We buy houses in
                  <span className="highlights"> any condition</span>, so there's
                  no need for you to make any repairs before you sell. Whether
                  your house needs major renovations or just a bit of sprucing
                  up, we'll take it off your hands and give you a fair price.
                  <br />
                  <br />
                  We will assess your property and provide you with a
                  <span className="highlights"> no-obligation offer</span>,
                  which means you can sell your house
                  <span className="highlights"> quickly and easily </span>
                  without any added stress.
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <div className="icons">
            {PORTFOLIO_ICONS_LEFT.map((item) => {
              return (
                <div className="icon" key={item.label}>
                  <img src={item.icon} alt={item.label} className="icon-img" />
                  <span className={item.left ? `left-label` : "right-label"}>
                    {item.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="section-six">
        <div className="container">
          <div className="transbox">
            <h2 className="title">Ready to Sell or Buy?</h2>
            <p className="message">
              Contact us today and let us help you buy or sell your property
              with ease. <br />
              We are committed to providing you with the best possible service
              and achieving great results for you.
            </p>
            <div className="button">
              <Button
                className="about-us"
                size="large"
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { LandingPage };
