import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "../../components/Navigation/Navigation.tsx";
import PropertiesBackground from "../../images/properties_background.png";
import { Footer } from "../../components/Footer/Footer.tsx";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Popover,
  Image,
  Pagination,
  Modal,
  Typography,
} from "antd";
import Icon, { SearchOutlined } from "@ant-design/icons";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "./properties.scss";
import { PropertyQuickLook } from "../../components/PropertyQuickLook/PropertyQuickLook.tsx";
import { Rating, TablePagination } from "@mui/material";
import { publicRequest } from "../../requestMethods.js";
import { fullAddress, numberFormatter } from "../../helper.ts";
import emailjs, { sendForm } from "@emailjs/browser";
import { Grid, TextField } from "@mui/material";
import { Button as ButtonMUI } from "@mui/material";
import { Input } from "antd";
import validate from "validate.js";

const { Paragraph, Text } = Typography;

export interface PropertyInterface {
  id: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: number;
  };
  details: {
    type: string;
    beds: number;
    baths: number;
    sqft: number;
    yearBuilt: number;
  };
  documents: [];
  estimations: {
    arv: number;
    rehab: number;
    rent: number;
  };
  images: [];
  location_rating: number;
  price: number;
  description: string;
  status: string;
  isActive: boolean;
}

const MOCK_PROPERTY = [
  {
    address: {
      street: "###",
      city: "###",
      state: "##",
      zip: "#####",
    },
    price: 0,
    details: {
      type: "Single-Family",
      beds: 0,
      baths: 0,
      sqft: 0,
      yearBuilt: 0,
    },
    location_rating: 0,
    estimations: {
      arv: 0,
      rehab: 0,
      rent: 0,
    },
    images: [
      "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921",
    ],
    documents: [],
    description: "Test",
    isActive: true,
    status: "Active",
  },
];

const Properties = () => {
  const [visible, setVisible] = useState(false);
  const [property, setProperty] = useState({} as PropertyInterface);
  const [loaded, setLoaded] = useState(false);

  // GET PROPERTIES
  const [properties, setProperties] = useState(MOCK_PROPERTY);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const res = await publicRequest.get(`/getProperties`);
        const active = res.data.filter((item) => item.isActive == true);

        setProperties(active);
      } catch (err) {}
    };
    getProperties();
  }, []);

  const onClickPreview = (item) => {
    setVisible(true);
    setProperty(item);
  };

  const previewImage = () => {
    return (
      <Image.PreviewGroup
        preview={{
          visible,
          onVisibleChange: (vis) => setVisible(vis),
        }}
      >
        {property?.images?.map((image, index) => {
          return <Image src={image} key={index} />;
        })}
      </Image.PreviewGroup>
    );
  };

  const numEachPage = 10;

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const setModal = (item) => {
    setIsModalOpen(true);
    setProperty(item);
  };

  const handleChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
    setCurrentPage(value);
  };

  const form = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [result, showResult] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    showResult(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // console.log(form.current);
    emailjs
      .sendForm(
        "service_jowazvn",
        "template_eydqr2l",
        form.current,
        "Ma8AOLUZL9V_wLtU0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setFormState((formState) => ({
      ...formState,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    }));
    showResult(true);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const schema = {
    name: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 128,
      },
    },
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: true,
      length: {
        maximum: 300,
      },
    },
  };

  const handleChangeForm = (e) => {
    e.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        property_address: fullAddress(property.address),
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };
  return (
    <div className="Properties">
      <Navigation />
      <img src={PropertiesBackground} alt="bg" className="background"></img>
      <div className="section">
        <div className="title">Browse Properties</div>
        <div className="content">
          <div className="cards">
            <Row gutter={16} wrap>
              {properties &&
                properties?.length > 0 &&
                properties?.slice(minValue, maxValue)?.map((item) => {
                  return (
                    item.isActive && (
                      <Col flex="auto" md={12} lg={8} xl={8} key={item.id}>
                        <Card
                          className="card"
                          bordered={true}
                          loading={!loaded}
                          cover={
                            <>
                              <Image
                                preview={{ visible: false }}
                                src={item.images[0]}
                                onClick={() => onClickPreview(item)}
                                className="previewImage"
                                onLoad={() => setLoaded(true)}
                              />
                              <div style={{ display: "none" }}>
                                {previewImage()}
                              </div>
                            </>
                          }
                          hoverable
                          size="small"
                        >
                          <div className="addressContainer">
                            <span className="icon">
                              <Icon
                                component={LocationOnOutlinedIcon}
                                className="icon"
                              />
                            </span>
                            <Text
                              className="address"
                              ellipsis={{
                                tooltip: fullAddress(item.address),
                              }}
                            >{` ${fullAddress(item.address)}`}</Text>
                          </div>
                          <div className="item">
                            <span className="label">Price: </span>
                            <span className="value">
                              {numberFormatter.format(item.price).split(".")[0]}
                            </span>
                          </div>
                          <div className="item">
                            <span className="label">ARV: </span>
                            <span className="value">
                              {
                                numberFormatter
                                  .format(item.estimations.arv)
                                  .split(".")[0]
                              }
                            </span>
                          </div>
                          <div className="item">
                            <span className="label">Rehab Estimate: </span>
                            <span className="value">
                              {
                                numberFormatter
                                  .format(item.estimations.rehab)
                                  .split(".")[0]
                              }
                            </span>
                          </div>
                          <div className="item">
                            <span className="label">Location Rating: </span>
                            <span className="value">
                              <div>
                                <Rating
                                  className="rating"
                                  defaultValue={item.location_rating}
                                  value={item.location_rating}
                                  precision={0.5}
                                  readOnly
                                  size="small"
                                />
                              </div>
                            </span>
                          </div>
                          <Divider
                            className="divider"
                            style={{ marginTop: "5px" }}
                          />
                          <div className="buttons">
                            <Popover
                              content={<PropertyQuickLook property={item} />}
                              title={fullAddress(item?.address)}
                              trigger="click"
                            >
                              <Button
                                className={`quick-look-btn`}
                                size="small"
                                icon={
                                  <Icon
                                    component={SearchOutlined}
                                    className="icon"
                                  />
                                }
                                shape="round"
                              >
                                View Details
                              </Button>
                            </Popover>
                            <Button
                              className={`details-btn`}
                              size="small"
                              shape="round"
                              onClick={(e) => setModal(item)}
                            >
                              More Info
                            </Button>
                            <Modal
                              title="Contact us for more info"
                              open={isModalOpen}
                              footer={null}
                              // onOk={handleOk}
                              onCancel={() => closeModal()}
                            >
                              <form
                                headers="application/json"
                                name="contact-form"
                                onSubmit={sendEmail}
                                ref={form}
                              >
                                <TextField
                                  variant="outlined"
                                  id="standard"
                                  size="medium"
                                  fullWidth
                                  style={{ marginBottom: 10, marginTop: 10 }}
                                  name="property_address"
                                  label="Property Address"
                                  type="text"
                                  value={fullAddress(property.address)}
                                  key="property"
                                />
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      id="standard-required"
                                      required
                                      placeholder="Name"
                                      variant="outlined"
                                      size="medium"
                                      name="name"
                                      fullWidth
                                      onChange={handleChangeForm}
                                      type="text"
                                      value={formState.values.name || ""}
                                      helperText={
                                        hasError("name")
                                          ? formState.errors.name[0]
                                          : null
                                      }
                                      error={hasError("name")}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      id="standard-required"
                                      required
                                      placeholder="E-mail"
                                      helperText={
                                        hasError("email")
                                          ? formState.errors.email[0]
                                          : null
                                      }
                                      error={hasError("email")}
                                      value={formState.values.email || ""}
                                      variant="outlined"
                                      size="medium"
                                      name="email"
                                      fullWidth
                                      onChange={handleChangeForm}
                                      type="email"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={0}>
                                    <br />
                                    <TextField
                                      required
                                      id="standard-required"
                                      label="Message"
                                      defaultValue="Hi, I would like to learn more about this property."
                                      multiline
                                      rows={3}
                                      variant="outlined"
                                      name="message"
                                      fullWidth
                                      style={{ marginTop: -15 }}
                                      className="message"
                                      onChange={handleChangeForm}
                                      type="message"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <ButtonMUI
                                      size="medium"
                                      variant="outlined"
                                      color="primary"
                                      disabled={!formState.isValid}
                                      className="send-button"
                                      onClick={sendEmail}
                                      style={{ marginBottom: 10 }}
                                    >
                                      Send
                                    </ButtonMUI>
                                  </Grid>
                                </Grid>
                              </form>
                              {result && (
                                <span
                                  style={{
                                    marginLeft: 2,
                                    marginTop: 4,
                                    color: "#d39444",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Thank you for contacting us about this
                                  property. We'll be in touch shortly!
                                </span>
                              )}
                            </Modal>
                          </div>
                        </Card>
                      </Col>
                    )
                  );
                })}
            </Row>
          </div>
        </div>
        <div className="pagination">
          <Pagination
            defaultCurrent={currentPage}
            current={currentPage}
            onChange={handleChange}
            total={properties.length}
            defaultPageSize={numEachPage}
            responsive
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            simple
            style={{ marginBottom: 15 }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export { Properties };
