import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./subscribeForm.scss";
import emailjs, { sendForm } from "@emailjs/browser";
import validate from "validate.js";
import { Button, TextField } from "@mui/material";

const SubscribeForm = () => {
  const form = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const schema = {
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: true,
      length: {
        maximum: 300,
      },
    },
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jowazvn",
        "template_0k39u7v",
        form.current,
        "Ma8AOLUZL9V_wLtU0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setFormState((formState) => ({
      ...formState,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    }));
  };

  const handleChange = (e) => {
    e.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <div className="SubscribeForm">
      <form
        headers="application/json"
        name="contact-form"
        onSubmit={sendEmail}
        style={{ display: "flex" }}
        ref={form}
      >
        <TextField
          placeholder="E-mail"
          sx={{ input: { color: "white" } }}
          variant="standard"
          size="small"
          name="email"
          helperText={hasError("email") ? formState.errors.email[0] : null}
          error={hasError("email")}
          onChange={handleChange}
          type="email"
          value={formState.values.email || ""}
          color="success"
        />
        <Button
          size="small"
          className="subscribe-button"
          variant="outlined"
          disabled={!formState.isValid}
          onClick={sendEmail}
        >
          Subscribe
        </Button>
      </form>
    </div>
  );
};

export { SubscribeForm };
