import React from "react";
import "./aboutUs.scss";
import Header_Background from "../../images/aboutUsHeaderBg.png";
import Core_Values from "../../images/aboutUs_coreValues_img.png";
import { Navigation } from "../../components/Navigation/Navigation.tsx";
import { Divider } from "antd";
import { Footer } from "../../components/Footer/Footer.tsx";

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <Navigation />
      <div className="section-one">
        <img src={Header_Background} alt="bg" className="image"></img>
        <div className="content">
          <div className="title">Our Story</div>
          <div className="message">
            We are a family business of real estate investors who specialize in
            finding properties that need some TLC. We believe that there is
            great potential in properties that have been neglected or need
            repairs, and we are committed to bringing them back to life. We take
            pride in our work and are passionate about restoring properties to
            their former glory. Our team of experts has the knowledge and skills
            to handle all aspects of the renovation process, from design to
            construction.
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="content">
          <div className="left">
            <img src={Core_Values} alt="bg" className="image"></img>
          </div>
          <div className="right">
            <div className="title">Core Values</div>
            <Divider className="divider" />
            <div className="message">
              At Keys & Contracts, our core values define who we are and guide
              our every decision and interaction. Integrity is the foundation of
              our business, as we believe in always doing what is right and
              maintaining the highest ethical standards. We strive for
              excellence in everything we do, from the properties we represent
              to the services we provide, ensuring exceptional quality and
              customer satisfaction.
            </div>
          </div>
        </div>
      </div>
      <div className="section-three">
        <div className="container">
          <div className="content">
            <div className="title">Mission Statement</div>
            <div className="message">
              Our mission at Keys & Contracts is to help our clients achieve
              their real estate goals by providing them with exceptional service
              and expertise. We are committed to finding properties that meet
              our investor's needs and providing them with the best possible
              returns on their investments. We believe in transparency, honesty,
              and integrity, and we are dedicated to ensuring that our clients
              are satisfied with our work. Our goal is to make the process of
              buying and selling properties as smooth and easy as possible for
              our clients, and we use our knowledge and skills to help them
              achieve their financial objectives. We are passionate about real
              estate and are committed to helping our clients succeed in this
              exciting and dynamic industry.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { AboutUs };
