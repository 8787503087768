// import "antd/dist/antd.min.css";
import { Button, Layout, Menu, Card, Col, Row, Badge } from "antd";
import {
  TrophyOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  FileImageOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "./admin.scss";
import { AccountCircle } from "@material-ui/icons";
import { AddProperty } from "../../components/Admin/AddProperty.tsx";
import { ManageProperties } from "../../components/Admin/ManageProperties.tsx";
import { adminRequest, publicRequest } from "../../requestMethods";
import { useSelector } from "react-redux";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Admin = () => {
  const admin = useSelector((state) => state.currentUser);

  const [collapsed, setCollapsed] = useState(false);
  const [selection, setSelection] = useState();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/admin/login";
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (e) => {
    setData(e.key);
  };

  const setData = (selected) => {
    switch (selected) {
      case "addProperty":
        setSelection(<AddProperty />);
        break;
      case "manageProperties":
        setSelection(<ManageProperties />);
        break;
      default:
        setSelection("");
    }
  };

  return (
    <div className="Admin">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item
              key="manageProperties"
              icon={<EditOutlined />}
              onClick={(e) => handleClick(e)}
            >
              Manage Properties
            </Menu.Item>
            <Menu.Item
              key="addProperty"
              onClick={(e) => handleClick(e)}
              icon={<PlusOutlined />}
            >
              Add Property
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="header" style={{ padding: 0 }}>
            <div className="userInfo">
              <AccountCircle />
              <div className="user">{admin.username}</div>
              <LogoutOutlined className="button" onClick={handleLogout} />
            </div>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              {selection ? (
                selection
              ) : (
                <Layout>
                  <Header>
                    <Button>
                      <a
                        href="https://dashboard.emailjs.com/sign-in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check Messages
                      </a>
                    </Button>
                    <Button style={{ marginLeft: "50px" }}>
                      <a
                        href="https://podio.com/real-estate-fzasom2aku/real-estate-workspace"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Go to Podio
                      </a>
                    </Button>
                    <Button style={{ marginLeft: "50px" }}>
                      <a
                        href="https://ohidetrinidadestates.snaphrm.com/login"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Go to SnapHRM
                      </a>
                    </Button>
                    <Button style={{ marginLeft: "50px" }}>
                      <a
                        href="https://www.zillow.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Go to Zillow
                      </a>
                    </Button>
                  </Header>
                  {/* <Content>
                    <br />
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        <Col span={20}>
                          <Card title="Upcoming Games" bordered={false}>
                            {upcomingGames()}
                          </Card>
                          <br />
                        </Col>
                        <Col span={8}>
                          <Card
                            title="Players Registered Last 5 Days"
                            bordered={false}
                          >
                            {playersLastFiveDays()}
                          </Card>
                        </Col>
                        <Col span={8}>
                          <Card
                            title="Players Unassigned to a Team"
                            bordered={false}
                          >
                            {playersUnassigned()}
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Content> */}
                </Layout>
              )}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Keys & Contracts ©2023
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export { Admin };
