import React, { useState } from "react";
import { PropertyInterface } from "../../pages/Properties/Properties";
import { numberFormatter } from "../../helper.ts";
import "./propertyQuickLook.scss";
import { DollarOutlined } from "@ant-design/icons";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

interface PropertyQuickLookInterface {
  property: PropertyInterface;
}
const PropertyQuickLook = (property: PropertyQuickLookInterface) => {
  return (
    <div className="PropertyQuickLook">
      <div className="mainDetails">
        <div className="item">
          <span className="label">Status: </span>
          <span className="value">{property.property.status}</span>
        </div>
        {property.property.description != "" && (
          <div className="desc">
            <span className="label">Description: </span>
            <div className="description-container">
              <span className="value-description">
                {property.property.description}
              </span>
            </div>
          </div>
        )}
        <br />
        <div className="item">
          <BedOutlinedIcon className="icon" />
          <span className="label">Beds: </span>
          <span className="value">{property.property.details.beds}</span>
        </div>
        <div className="item">
          <BathtubOutlinedIcon className="icon" />
          <span className="label">Baths: </span>
          <span className="value">{property.property.details.baths}</span>
        </div>
      </div>
      <div className="item">
        <SquareFootOutlinedIcon className="icon" />
        <span className="label">Sqft: </span>
        <span className="value">{property.property.details.sqft}</span>
      </div>
      <div className="item">
        <CalendarMonthOutlinedIcon className="icon" />
        <span className="label">Year Built: </span>
        <span className="value">{property.property.details.yearBuilt}</span>
      </div>
      <div className="item">
        <HolidayVillageOutlinedIcon className="icon" />
        <span className="label">Type: </span>
        <span className="value">{property.property.details.type}</span>
      </div>
      <br />
      <div className="details">
        <div className="item">
          <MonetizationOnOutlinedIcon className="icon" />
          <span className="label">Price: </span>
          <span className="value">
            {numberFormatter.format(property.property.price).split(".")[0]}
          </span>
        </div>
        <div className="item">
          <LocalOfferOutlinedIcon className="icon" />
          <span className="label">ARV: </span>
          <span className="value">
            {
              numberFormatter
                .format(property.property.estimations.arv)
                .split(".")[0]
            }
          </span>
        </div>
        <div className="item">
          <HandymanOutlinedIcon className="icon" />
          <span className="label">Rehab Estimate: </span>
          <span className="value">
            {
              numberFormatter
                .format(property.property.estimations.rehab)
                .split(".")[0]
            }
          </span>
        </div>
        <div className="item">
          <PaymentsOutlinedIcon className="icon" />
          <span className="label">Rent Estimate: </span>
          <span className="value">
            {
              numberFormatter
                .format(property.property.estimations.rent)
                .split(".")[0]
            }
          </span>
        </div>
        {/* {property.property.documents.length > 0 && (
          <div className="item">
            <span className="label">Diligence Documents: </span>
            <span className="value">{property.property.documents}</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export { PropertyQuickLook };
