import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Popconfirm,
  message,
  Modal,
  Form,
  InputNumber,
  Select,
  Switch,
  Upload,
  Rate,
  Divider,
} from "antd";
import {
  InboxOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./properties.scss";
import { adminRequest } from "../../requestMethods";
import app from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { css } from "@emotion/css";
import { CSS } from "@dnd-kit/utilities";

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: any;
}

const ManageProperties = () => {
  const [setSearchText] = useState("");
  const [modalVisible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);

  const DraggableUploadListItem = ({
    originNode,
    file,
  }: DraggableUploadListItemProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: file.uid,
    });

    const style: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "move",
    };

    // prevent preview event when drag end
    const className = isDragging
      ? css`
          a {
            pointer-events: none;
          }
        `
      : "";

    return (
      <div
        ref={setNodeRef}
        style={style}
        className={className}
        {...attributes}
        {...listeners}
      >
        {/* hide error tooltip when dragging */}
        {file.status === "error" && isDragging
          ? originNode.props.children
          : originNode}
      </div>
    );
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const { Dragger } = Upload;

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const beforeUpload = (file) => {
    if (
      !["image/jpeg", "image/png", "image/webp", "image/svg"].includes(
        file.type
      )
    ) {
      message.error(`${file.name} is not a valid image type`, 2);
      return null;
    }
    return false;
  };

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));

  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const photosUrl = [];

  // GET Properties
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const res = await adminRequest.get("/getProperties");
        setProperties(res.data);
      } catch (err) {}
    };
    getProperties();
  }, []);

  const [fileList, setFileList] = useState([]);

  const showModal = (e) => {
    let currProperty = null;
    for (const property of properties) {
      if (property._id === e) {
        currProperty = property;
      }
    }

    form.setFieldsValue({
      street: currProperty.address.street,
      city: currProperty.address.city,
      state: currProperty.address.state,
      zip: currProperty.address.zip,
      price: currProperty.price,
      type: currProperty.details.type,
      beds: currProperty.details.beds,
      baths: currProperty.details.baths,
      sqft: currProperty.details.sqft,
      yearBuilt: currProperty.details.yearBuilt,
      location_rating: currProperty.location_rating,
      arv: currProperty.estimations.arv,
      rehab: currProperty.estimations.rehab,
      rent: currProperty.estimations.rent,
      images: currProperty.images,
      documents: currProperty.documents,
      isActive: currProperty.isActive,
      description: currProperty.description,
      status: currProperty.status,
      id: currProperty._id,
    });
    setImages(currProperty.images);
    const imageURLs = currProperty.images.map((str, index) => ({
      url: str,
      id: index + 1,
    }));
    setFileList(imageURLs);
    setVisible(true);
  };

  const existingURL = [];
  fileList.map((files) => {
    existingURL.push(files.url);
  });

  // const noPhotosUploaded =
  //   existingURL.length == images.length &&
  //   existingURL.every(function (element, index) {
  //     return element === images[index];
  //   });

  const newPhotos = fileList.filter((item) => item.name);

  var todayDate = new Date().toISOString().slice(0, 10);

  const handleFinish = async () => {
    setConfirmLoading(true);

    if (newPhotos.length > 0) {
      // if has new photos
      // upload new photos
      try {
        setSubmitting(true);

        await Promise.all(
          newPhotos.map(async (file) => {
            // const fileName = new Date().getTime() + "_" + file.name;
            const fileName = `${form.getFieldValue([
              "street",
            ])}, ${form.getFieldValue(["city"])}, ${form.getFieldValue([
              "state",
            ])}, ${form.getFieldValue(["zip"])}/${todayDate}/${file.name}`;
            const storage = getStorage(app);
            const storageRef = ref(storage, fileName);
            try {
              const snapshot = await uploadBytesResumable(
                storageRef,
                file.originFileObj
              );
              const downloadURL = await getDownloadURL(snapshot.ref);
              photosUrl.push(downloadURL);
              const allPhotos = [...images, ...photosUrl];
              updateProperty(allPhotos);
            } catch (e) {
              console.log(e);
            }
            // setFileList(images);
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        message.success("Property Successfully Updated.");
        await new Promise((r) => setTimeout(r, 200));
        setSubmitting(false);
        setConfirmLoading(false);
        setVisible(false);
      }
    } else {
      //keep old photos
      message.success("Property Successfully Updated.");
      await new Promise((r) => setTimeout(r, 200));
      updateProperty(existingURL);
      setSubmitting(false);
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  const updateProperty = async (imageUrls) => {
    try {
      await adminRequest.put(`/updateProperty/${form.getFieldValue(["id"])}`, {
        address: {
          street: form.getFieldValue(["street"]),
          city: form.getFieldValue(["city"]),
          state: form.getFieldValue(["state"]),
          zip: form.getFieldValue(["zip"]),
        },
        price: form.getFieldValue(["price"]),
        details: {
          type: form.getFieldValue(["type"]),
          beds: form.getFieldValue(["beds"]),
          baths: form.getFieldValue(["baths"]),
          sqft: form.getFieldValue(["sqft"]),
          yearBuilt: form.getFieldValue(["yearBuilt"]),
        },
        location_rating: form.getFieldValue(["location_rating"]),
        estimations: {
          arv: form.getFieldValue(["arv"]),
          rehab: form.getFieldValue(["rehab"]),
          rent: form.getFieldValue(["rent"]),
        },
        documents: [
          {
            title: form.getFieldValue(["title"]),
            link: form.getFieldValue(["link"]),
          },
        ],
        isActive: form.getFieldValue(["isActive"]),
        status: form.getFieldValue(["status"]),
        description: form.getFieldValue(["description"]),
        images: imageUrls,
      });

      // UPDATE PROPERTIES
      const update = await adminRequest.get("/getProperties");
      setProperties(update.data);
    } catch (err) {
      console.log(err);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  const handleCancel = () => {
    setVisible(false);
  };

  let searchInput = "";

  const setPropertyActive = async (e, status) => {
    try {
      await adminRequest.put(`/updateProperty/${e}`, {
        isActive: !status,
      });

      // UPDATE PROPERTIES
      const update = await adminRequest.get("/getProperties");
      setProperties(update.data);
    } catch (err) {
      console.log(err);
    }
  };

  async function confirmDelete(propertyId) {
    // DELETE PROPERTY
    await adminRequest.delete(`/${propertyId}`);

    // UPDATE PROPERTY
    const update = await adminRequest.get("/getProperties");
    setProperties(update.data);
    message.success("Property Successfully Deleted");
  }

  function cancel(e) {}

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" });
  };

  const columns = [
    {
      title: "Street",
      dataIndex: ["street"],
      key: "street",
      ...getColumnSearchProps("street"),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "City",
      dataIndex: ["city"],
      key: "city",
      ...getColumnSearchProps("city"),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "State",
      dataIndex: ["state"],
      key: "state",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Zip",
      dataIndex: ["zip"],
      key: "zip",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Price",
      dataIndex: ["price"],
      key: "team",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Show on site",
      dataIndex: ["id", "isActive"],
      key: "active",
      render: (text, record) => (
        <Switch
          checked={record["isActive"]}
          onChange={(e) => setPropertyActive(record["id"], record["isActive"])}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: ["id"],
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={(e) => showModal(record["id"])}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this property?"
            onConfirm={(e) => confirmDelete(record["id"])}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const datasource = properties.map((property) => ({
    street: property.address.street,
    city: property.address.city,
    state: property.address.state,
    zip: property.address.zip,
    price: property.price,
    type: property.details.type,
    beds: property.details.beds,
    baths: property.details.baths,
    sqft: property.details.sqft,
    yearBuilt: property.details.yearBuilt,
    location_rating: property.location_rating,
    arv: property.estimations.arv,
    rehab: property.estimations.rehab,
    rent: property.estimations.rent,
    images: property.images,
    documents: property.documents,
    isActive: property.isActive,
    status: property.status,
    id: property._id,
  }));

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 10,
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="Teams">
      <h1 style={{ marginBottom: 10 }}>Properties</h1>
      <div className="container">
        <Table columns={columns} dataSource={datasource} />
      </div>
      <Modal
        title="Edit Property"
        visible={modalVisible}
        onOk={(e) => handleFinish}
        width={800}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFinish}
            loading={confirmLoading}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} {...layout} name="nest-messages">
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            General Information
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />
          <Form.Item
            name="street"
            label="Street"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: 123 Main Street" />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: New York City" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: NY" />
          </Form.Item>
          <Form.Item
            name="zip"
            label="Zip Code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="ex: 12345" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[]}>
            <Input.TextArea showCount maxLength={1000} />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[]}>
            <Select placeholder="Please Select...">
              <Select.Option value="Active" key="active">
                Active
              </Select.Option>
              <Select.Option value="Pending" key="pending">
                Pending
              </Select.Option>
              <Select.Option value="Contract" key="contract">
                Under Contract
              </Select.Option>
              <Select.Option value="Sold" key="sold">
                Sold
              </Select.Option>
            </Select>
          </Form.Item>
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            Property Details
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />
          <Form.Item
            name="type"
            label="Property Type"
            rules={[
              {
                required: "true",
              },
            ]}
          >
            <Select placeholder="Please Select...">
              <Select.Option value="Single-Family" key="Single-Family">
                Single-Family
              </Select.Option>
              <Select.Option value="Multi-Family" key="Multi-Family">
                Multi-Family
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="beds"
            label="Beds"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
                max: 10,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="baths"
            label="Baths"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
                max: 10,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="sqft"
            label="Sqft"
            rules={[
              {
                required: "true",
                type: "number",
                min: 1,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="yearBuilt"
            label="Year Built"
            rules={[
              {
                required: "true",
                type: "number",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="location_rating"
            label="Location Rating"
            rules={[
              {
                required: "true",
                type: "number",
              },
            ]}
          >
            <Rate allowHalf />
          </Form.Item>
          <div
            className="section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bolder",
            }}
          >
            Estimations
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />{" "}
          <Form.Item
            name="arv"
            label="ARV"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            name="rehab"
            label="Rehab"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            name="rent"
            label="Rent"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Divider style={{ marginTop: 5, marginBottom: 10 }} />{" "}
          <Form.Item
            name="photo"
            label="Upload Photo"
            valuePropName="photo"
            getValueFromEvent={normFile}
          >
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext
                items={fileList.map((i) => i.uid)}
                strategy={verticalListSortingStrategy}
              >
                <Dragger
                  listType="picture-card"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  onRemove={onRemove}
                  multiple
                  defaultFileList={images}
                  itemRender={(originNode, file) => (
                    <DraggableUploadListItem
                      originNode={originNode}
                      file={file}
                    />
                  )}
                >
                  <Modal
                    open={previewVisible}
                    title={"Image Preview"}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="preview"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </SortableContext>
            </DndContext>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export { ManageProperties };
