import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage/LandingPage.tsx";
import { AboutUs } from "./pages/AboutUs/AboutUs.tsx";
import { ContactUs } from "./pages/ContactUs/ContactUs.tsx";
import { Properties } from "./pages/Properties/Properties.tsx";
import { AdminLogin } from "./pages/Admin/AdminLogin.tsx";
import { Admin } from "./pages/Admin/Admin.tsx";
import { useSelector } from "react-redux";

function App() {
  const admin = useSelector((state) => state.currentUser);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/aboutUs" element={<AboutUs />}></Route>
        <Route path="/properties" element={<Properties />}></Route>
        <Route path="/contact" element={<ContactUs />}></Route>
        <Route path="/admin/login" element={<AdminLogin />}></Route>
        {admin && <Route path="/admin" element={<Admin />}></Route>}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
