import React from "react";
import FullLogo from "../../images/FullLogo.png";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import "./navigation.scss";
import { Link, useNavigate } from "react-router-dom";

const Navigation = () => {
  const MENU_ITEMS = [
    { name: "Home", url: "/" },
    { name: "About Us", url: "/aboutUs" },
    // { name: "Strategy", url: "/strategy" },
  ];

  const items = [
    { key: "/", name: "Home", label: "Home" },
    { key: "/aboutUs", name: "About Us", label: "About Us" },
    { key: "/properties", name: "Properties", label: "Properties" },
    { key: "/contact", name: "Contact", label: "Contact Us" },
  ];

  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = ({ key }) => {
    navigate(key);
  };

  return (
    <div className="Navigation">
      <div className="header">
        <img src={FullLogo} alt="logo" className="logo"></img>
        <div className="menu">
          {MENU_ITEMS.map((item) => {
            return (
              <Link to={item.url}>
                <Button
                  className="menu-item"
                  size="large"
                  type="text"
                  key={item.name}
                >
                  {item.name}
                </Button>
              </Link>
            );
          })}
          <Link to="/properties" style={{ marginRight: 10 }}>
            <Button
              className={`menu-item primary-button`}
              size="large"
              type="primary"
            >
              Properties
            </Button>
          </Link>
          <Link to="/contact">
            <Button className={`menu-item default-button`} size="large">
              Contact Us
            </Button>
          </Link>
        </div>
        <div className="hamburger">
          <Dropdown menu={{ items, onClick }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div className="hamburger-icon">
                  <MenuOutlined />
                </div>
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export { Navigation };
