import React, { useEffect, useRef, useState } from "react";
import "./contactUs.scss";
import ContactUs_Bg from "../../images/contactUs.png";
import { Navigation } from "../../components/Navigation/Navigation.tsx";
import { Footer } from "../../components/Footer/Footer.tsx";
import emailjs, { sendForm } from "@emailjs/browser";
import SendIcon from "@mui/icons-material/Send";
import Icon, { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { Grid, TextField, Button } from "@mui/material";
import validate from "validate.js";

const ContactUs = () => {
  const [result, showResult] = useState(false);
  const form = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const schema = {
    name: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 128,
      },
    },
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: true,
      length: {
        maximum: 300,
      },
    },
    message: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 2000,
      },
    },
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jowazvn",
        "template_42yiega",
        form.current,
        "Ma8AOLUZL9V_wLtU0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setFormState((formState) => ({
      ...formState,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    }));
    showResult(true);
  };

  const handleChange = (e) => {
    e.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <div className="ContactUs">
      <Navigation />
      <div className="section-one">
        <img src={ContactUs_Bg} alt="bg" className="image"></img>
        <div className="content">
          <div className="title">Connect with us</div>
          <div className="message">
            {
              <div className="formContainer">
                <div className="left">
                  <div className="section">
                    <div className="text">
                      To learn more about how we can help you with your real
                      estate needs, please provide your information, and we will
                      contact you shortly.
                    </div>
                    <Divider />
                    <div className="information">
                      <div className="company">Keys & Contracts</div>
                      <div className="email">
                        <Link
                          to="javascript:void(0)"
                          onClick={(e) => {
                            window.location.href =
                              "mailto:support@keysandcontracts.com";
                          }}
                          className="link"
                        >
                          support@keysandcontracts.com
                        </Link>
                      </div>
                      <Icon
                        component={FacebookOutlined}
                        className="icon"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/keysandcontracts1"
                          )
                        }
                        style={{ fontSize: "20px", color: "" }}
                      />
                      <Icon
                        component={InstagramOutlined}
                        className="icon"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/keysandcontracts/"
                          )
                        }
                        style={{ fontSize: "20px", color: "" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="right">
                  <form
                    headers="application/json"
                    name="contact-form"
                    onSubmit={sendEmail}
                    ref={form}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={5.5} sm={4} md={6} lg={5.8}>
                        <TextField
                          placeholder="Name"
                          variant="outlined"
                          size="medium"
                          required
                          name="name"
                          id="name"
                          className="name-form"
                          fullWidth
                          helperText={
                            hasError("name") ? formState.errors.name[0] : null
                          }
                          error={hasError("name")}
                          onChange={handleChange}
                          type="text"
                          value={formState.values.name || ""}
                        />
                      </Grid>
                      <Grid item xs={5.5} sm={4} md={6} lg={5.23}>
                        <TextField
                          placeholder="E-mail"
                          variant="outlined"
                          size="medium"
                          name="email"
                          fullWidth
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          error={hasError("email")}
                          onChange={handleChange}
                          type="email"
                          value={formState.values.email || ""}
                        />
                      </Grid>
                      <Grid item xs={11} sm={8} md={12} lg={11}>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={6}
                          placeholder="Message"
                          variant="outlined"
                          name="message"
                          fullWidth
                          helperText={
                            hasError("message")
                              ? formState.errors.message[0]
                              : null
                          }
                          className="message"
                          error={hasError("message")}
                          onChange={handleChange}
                          type="message"
                          value={formState.values.message || ""}
                        />
                      </Grid>
                      <Grid item xs={11} sm={8} md={12} lg={11}>
                        <Button
                          size="medium"
                          variant="outlined"
                          fullWidth
                          color="primary"
                          endIcon={<SendIcon />}
                          disabled={!formState.isValid}
                          className="send-button"
                          onClick={sendEmail}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  {result && (
                    <span className="result">
                      Thank you for contacting us. We'll be in touch shortly!
                    </span>
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { ContactUs };
